import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyArZBJZkUr3B3eLlz1YHNzqcKnA5WH-X78",
    authDomain: "edenred-sales-meeting-2023.firebaseapp.com",
    projectId: "edenred-sales-meeting-2023",
    storageBucket: "edenred-sales-meeting-2023.appspot.com",
    messagingSenderId: "785653785049",
    appId: "1:785653785049:web:517da10f01d9eacb8d39c8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}