import React, {useState} from "react";
import {Box, Stack} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import Typography from "@mui/material/Typography";
import {Check, Upload} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import CustomTooltip from "./CustomTooltip";

const SetImage = ({logoUrl, handleUpdateImage, caption, tooltip}) => {
    const fileTypes = ["JPEG", "PNG", "JPG"];
    const [file, setFile] = useState(null)
    const handleChangeLogo = (file) => {
        handleUpdateImage(file);
        setFile(file)
    };
    return (
        <Box sx={{py: 1}}>
            <FileUploader
                multiple={false}
                handleChange={handleChangeLogo}
                name="file"
                types={fileTypes}
                children={
                    <Box sx={{
                        width: '100%',
                        p: 3,
                        mt: 0.4,
                        border: `2px dashed ${customTheme.palette.accent.main}`,
                        borderRadius: '1.5rem',
                        textAlign: 'center'
                    }}>
                        { logoUrl && <img src={logoUrl} width={'100px'}/> }
                        {
                            tooltip ?
                                <CustomTooltip title={caption} children={
                                    <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} spacing={4}>
                                        {!file ?
                                            <Upload sx={{fontSize: '2rem', color: customTheme.palette.primary.main}}/>
                                            : <Check sx={{fontSize: '3rem', color: 'green'}}/>}
                                        <Typography
                                            variant={"body1"}>{!file ? 'Carica o trascina un\'immagine' : 'Caricato' + ' ' + file.name}</Typography>
                                    </Stack>
                                }/>
                                : <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} spacing={4}>
                                    {!file ?
                                        <Upload sx={{fontSize: '2rem', color: customTheme.palette.primary.main}}/>
                                        : <Check sx={{fontSize: '3rem', color: 'green'}}/>}
                                    <Typography
                                        variant={"body1"}>{!file ? 'Carica o trascina un\'immagine' : 'Caricato' + ' ' + file.name}</Typography>
                                </Stack>
                        }
                    </Box>
                }
            />
            {caption && !tooltip &&
                <Typography sx={{mb: 1}} variant={'caption'} color={customTheme.palette.primary.main}>
                    {caption}
                </Typography>}
        </Box>
    )
}

export default SetImage
