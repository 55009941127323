import * as React from 'react';
import {useContext, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import CustomLogo from "../CustomLogo";
import {AccountCircle, Logout} from "@mui/icons-material";
import {appName} from "../../config";
import {
    alpha,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    ListItemIcon
} from "@mui/material";
import {GlobalContext} from "../../state/global";
import CustomTooltip from "../CustomTooltip";
import {auth} from "../../firebase/clientApp";
import {useNavigate} from "react-router-dom";
import {setSelectedRoute} from "../../state/global/globalActions";
import logoWinston from "../../assets/images/Logo winston - simbolo - bianco@1K.png"

const CustomAppBar = () => {
    const [globalState, dispatch] = useContext(GlobalContext)

    const navigate = useNavigate()

    const email = auth.currentUser.email
    //const {email} = AuthService.getCurrentUserData()
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function handleOpenConfirmDialog() {
        setOpenConfirmDialog(!openConfirmDialog)
    }

    const settings = [
        { label: 'Account', icon: <AccountCircle/>, action: handleCloseUserMenu },
        { label: 'Logout', icon: <Logout/>, action: handleOpenConfirmDialog }
    ];

    const logOut = () => {
        auth.signOut().then(() => {
            navigate('/login', {replace:true})
            dispatch(setSelectedRoute('/'))
        }).catch((error) => {
            // An error happened.
        });
    }

    function handleLogout() {
        logOut()
    }

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box flexGrow={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <CustomLogo width={'110px'} bw={false}/>
                        <Typography sx={{margin: 'auto'}} variant={'inherit'} textAlign="center">
                            {globalState?.settings?.eventName || appName}
                        </Typography>
                        <Box>
                            <CustomTooltip
                                title="Open settings"
                                children={<IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                            <img src={globalState.settings?.logoUrl || logoWinston}
                                                 style={{
                                                     width: '50px',
                                                     height: '50px',
                                                     objectFit: 'contain',
                                                     borderRadius: '50%',
                                                     padding: 3,
                                                     background: alpha('#ffffff', 0.5)
                                                 }}>
                                            </img>
                                        </IconButton>}
                            />
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <Box p={1}>{email}</Box>
                                <Divider/>
                                {settings.map((setting) => (
                                    <MenuItem key={setting.label} onClick={setting.action}>
                                        <ListItemIcon>
                                            {setting.icon}
                                        </ListItemIcon>
                                        <Typography textAlign="center">{setting.label}</Typography>
                                    </MenuItem>
                                ))}
                                <Dialog open={openConfirmDialog} onClose={handleOpenConfirmDialog}>
                                    <DialogContent>
                                        <DialogContentText>
                                            Sei sicuro di voler uscire?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <DialogActions>
                                            <Button onClick={handleOpenConfirmDialog}>Annulla</Button>
                                            <Button onClick={handleLogout} variant={'submit'}
                                                    startIcon={<Logout/>}>Esci
                                            </Button>
                                        </DialogActions>
                                    </DialogActions>
                                </Dialog>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default CustomAppBar;