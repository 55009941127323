import React, {useState} from "react";
import CustomTooltip from "./CustomTooltip";
import {Button, Dialog, DialogContent} from "@mui/material";

const ImgPopUpComponent = ({qrCodeUrl, maxWidthPreview = '30px', maxWidthImg = '200px'}) => {
    const [open, setOpen] = useState(false)
    function handleToggleDialog() {
        setOpen(!open)
    }

    return (
        <>
            <CustomTooltip title={'Apri immagine'} children={
                <Button size={'small'}
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDialog()
                        }}>
                    <img src={qrCodeUrl} style={{maxWidth: maxWidthPreview}} onClick={() => {}}/>
                </Button>
            }/>
            {/*<CustomTooltip title={"Copia link"} children={
                <IconButton
                    onClick={async (event) => {
                        event.stopPropagation()
                        await navigator.clipboard.writeText(params.row.qrCodeUrl)
                    }}>
                    <ContentCopy fontSize={'small'}/>
                </IconButton>
            }/>*/}
            <Dialog open={open} onClose={handleToggleDialog} maxWidth={'md'}>
                <DialogContent>
                    <img src={qrCodeUrl} style={{width: maxWidthImg}}/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ImgPopUpComponent