import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemIcon
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect} from "react";
import CustomLoader from "../CustomLoader";
import ListItemText from "@mui/material/ListItemText";
import {Info} from "@mui/icons-material";
import {GlobalContext} from "../../state/global";
import DataStructuresService from "../../services/dataStructures.service";
import {setDataStructures, setGlobalSettings} from "../../state/global/globalActions";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";

export function OptionalCheckinUserData({userData, dividerColor = 'white'}) {
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data: dataSettings, loading: loadingSettings, error: errorSettings}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    const [{data: dataStructures, loading: loadingDataStructures, error: errorDataStructures}] = useAxios(
        DataStructuresService.dataStructuresUrl(), {method: "GET", useCache: false, manual: false}
    )

    useEffect(() => {
        if (dataSettings) {
            dispatch(setGlobalSettings(dataSettings.settings))
        }
    }, [dataSettings])

    useEffect(() => {
        if (dataStructures) {
            dispatch(setDataStructures(dataStructures.data))
        }
    }, [dataStructures])

    return (
        <Box>
            {
                globalState.settings && globalState.dataStructures &&
                <List>
                    {
                        globalState.settings.checkInFields?.map((field) => (
                            <>
                                <Divider color={dividerColor}/>
                                <ListItem sx={{px:0}}>
                                    {/*<ListItemIcon>
                                    {(userData && userData[field.id]) ?
                                        userData ? [field.id] === 'Si' ? <Restaurant/> : <NoMeals/>}
                                </ListItemIcon>*/}
                                    <ListItemIcon>
                                        {<Chip variant={'accent-outlined'}
                                               size={'small'}
                                               sx={{marginRight: 1}}
                                               label={field.label}
                                        />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={((userData && userData[field.id]) ? userData[field.id].toString() : '-')}
                                    />
                                </ListItem>
                            </>

                        ))
                    }
                    <Divider color={dividerColor}/>
                </List>
            }
            {(loadingSettings || loadingDataStructures) && <CustomLoader/>}
            {(errorSettings || errorDataStructures) && <Typography>Ops... qualcosa è andato storto</Typography>}
        </Box>
    );
}

const ConfirmCheckinDialog = ({open, handleClose, user, info = false}) => {
    return (
        <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Box display={'flex'} alignItems={'center'}>
                    <Info color={'primary'} sx={{mr: 2}}/>
                    {info ? "Informazioni" : "Checkin avvenuto con successo"}
                </Box>
            </DialogTitle>
            <DialogContent sx={{px:1}}>
                <DialogContent id="alert-dialog-description">
                    {!user && <CustomLoader/>}
                    {user ?
                        <Box>
                            <Typography gutterBottom variant={"h5"}>{user?.nome} {user?.cognome}</Typography>
                            <OptionalCheckinUserData userData={user}/>
                        </Box> : null}
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmCheckinDialog;
