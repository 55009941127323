import React from "react";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";

const dataStructuresUrl = (id) => {
    return `${API_URL}config/data-structures${id ? ('/'+id) : ''}`
}

const getDataStructures = () => {
    return axios.get(dataStructuresUrl(), { headers: authHeader() })
};

const saveDataStructures = (data) => {
    return axios.post(dataStructuresUrl(), {...data}, {headers: authHeader()})
};

const DataStructuresService = {
    dataStructuresUrl,
    getDataStructures,
    saveDataStructures,
};

export default DataStructuresService;